
import Request from '@/utils/request'

export function getSpecsListApi (data) {
  return Request({
    url: '/product/specs',
    params: data
  })
}

export function saveSpecsApi (data) {
  return Request({
    url: '/product/saveSpecs',
    method: 'post',
    data
  })
}

export function getTypesApi () {
  return Request({
    url: '/product/type/all'
  })
}

export function getSpecByTypeApi (data) {
  return Request({
    url: '/product/specsbytype',
    params: data
  })
}
export function getSpecItemApi (data) {
  return Request({
    url: '/product/specsItem',
    params: data
  })
}

export function delProductSpecApi (params) {
  return Request({
    url: '/product/delSpecsItem',
    params
  })
}
