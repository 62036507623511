<template>
  <el-dialog v-model="visible" :title="title" width="640px">
    <el-form ref="form" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="类别" prop="productTypeId">
        <el-select v-model="ruleForm.productTypeId" filterable :disabled="!!id" placeholder="请选择">
          <el-option v-for="(item,index) in types" :key="index" :label="item.productTypeName" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格" prop="productSpecsName">
        <el-input v-model="ruleForm.productSpecsName"></el-input>
      </el-form-item>
      <el-form-item label="单价" prop="productSpecPrice">
        <el-input type="number" v-model="ruleForm.productSpecPrice"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed, reactive, toRefs, onMounted, watch } from 'vue'
// import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'

import {
  saveSpecsApi,
  getTypesApi,
  getSpecItemApi
} from './fetch'

export default {
  props: {
    refreshList: Function,
    id: String
  },
  setup (props, ctx) {
    const form = ref(null)
    const state = reactive({
      visible: true,
      types: [],
      ruleForm: {
        productTypeId: '',
        productSpecsName: '',
        productSpecPrice: undefined
      },
      rules: {
        productTypeId: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        productSpecsName: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        productSpecPrice: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(async () => {
      state.types = await getTypesApi()
      if (props.id) {
        state.ruleForm = await getSpecItemApi({ productSpecsId: props.id })
      }
    })

    watch(() => state.visible, (newVal) => {
      if (!newVal) {
        setTimeout(() => {
          ctx.emit('close')
        }, 300)
      }
    })

    const title = computed(() => {
      return props.id ? '编辑规格' : '新增规格'
    })

    const onSubmit = async () => {
      try {
        await form.value.validate()
        const data = state.ruleForm
        data.productSpecPrice = +state.ruleForm.productSpecPrice
        await saveSpecsApi(data)
        ElMessage({
          message: '保存成功！',
          type: 'success'
        })
        state.visible = false
        props.refreshList(true)
      } catch (e) {
        ElMessage({
          message: e,
          type: 'error'
        })
      }
    }

    return {
      ...toRefs(state),
      title,
      form,
      onSubmit
    }
  }
}
</script>
